import React from "react";
import { Anchor, Row, Container, Button, Col, Image } from "react-bootstrap";
import microsoft from "../assets/microsoft.jpeg"
import profileImage from "../assets/profile.png";
import jci from "../assets/johnson_controls_logo.jpeg";
import fed from "../assets/federal_reserve_logo.jpeg";
import paccar from "../assets/paccar_logo.jpeg";
import army from "../assets/us_army_logo.jpeg";

const Hero = () => {
  const iconStyle = { width: "50px", height: "50px", borderRadius: "50%" };

  const heroStyles = {
      backgroundImage:
          "url('https://img.freepik.com/free-photo/high-view-blue-curved-sheets-paper_23-2148319068.jpg?t=st=1715494223~exp=1715497823~hmac=f37d8a101cd34ea76c96d6a444d579d45e947d6eed00fd5b330214baa4040fdf&w=1800')",
  };

  return (
    <div id="hero">
      <Container className="container-border" style={{heroStyles}}>
        <Row className="justify-content-center align-items-center">
          <Col xs={6} xl={6}>
            <h1>Hello, my name is Devin</h1>
            <p>
            Software Engineer @{" "}
            <span className="d-inline-flex align-items-center">
              Microsoft
              <Image 
                src={microsoft} 
                alt="Microsoft Logo" 
                style={{ width: "50px",  }} 
              />
            </span>
            </p>
            <Row>
              <Col>
                <Anchor href="#contact">
                  <Button>Contact</Button>
                </Anchor>
              </Col>
            </Row>
          </Col>
          <Col xs={6} xl={3}>
            <Image src={profileImage} roundedCircle fluid />
          </Col>
        </Row>
        <p className="mt-5">Some places i've worked</p>
        <Row className="border border-3 p-3" xs={3}>
          <Col xs={3}>
            <Image src={jci} style={iconStyle} />
            <span> Johnson Controls </span>
          </Col>
          <Col xs={3}>
            <Image src={fed} style={iconStyle} />
            <span> Federal Reserve </span>
          </Col>
          <Col xs={3}>
            <Image src={paccar} style={iconStyle} />
            <span> PACCAR </span>
          </Col>
          <Col xs={3}>
            <Image src={army} style={iconStyle} />
            <span> US Army </span>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Hero;
