import React from "react";
import { Container, Row, Col, Badge, Button, Spinner } from "react-bootstrap";
import "./Container.css";
import "./Experience.css";
import { get } from "@aws-amplify/api";

const Experience = () => {
  let [refreshing, setRefreshing] = React.useState(false);

  const resumeClicked = async () => {
    try {
      setRefreshing(true);
      let response = await get({
        apiName: "portfolioAPI",
        path: "/resume",
      }).response;
      let text = await response.body.text();
      window.open(text, "_blank");
    } catch (error) {
      console.error(error);
    } finally {
      setRefreshing(false);
    }
  };

  const jobHistory = [
    {
     company: "Microsoft",
     dates: "SEPT 2024 - Present",
     position: "Software Engineer 2",
     description: "Working in the Gaming Devices and AI team to provide the next generation of gaming devices",
     skills: [""]
    },
    {
      company: "Johson Controls/Tempered Networks",
      dates: "OCT 2022 - APR 2024",
      position: "Sr Cloud Engineer",
      description:
        "Worked as software engineer with a cloud focus.  I joined a Johnson Controls company called Tempered Networks, which provides a zero trust networking solution.  My team was focused on developing the user interface for this application that resides in the cloud and via hardware devices.",
      skills: ["ruby", "ember.js", "c++", "terraform", "multi-cloud"],
    },
    {
      company: "Federal Reserve Bank of Richmond",
      dates: "AUG 2021 - OCT 2022",
      position: "Sr Cloud Engineer",
      description:
        "Worked in an API development team where we were responsible for the DevOps and development of APIs to be used by all Federal Reserve banks.",
      skills: ["golang", "terraform", "packer", "AWS GovCloud"],
    },
    {
      company: "PACCAR",
      dates: "DEC 2019 - AUG 2021",
      position: "Sr Project Engineer/Architect",
      description:
        "Started off as an analytics engineer, and worked my way into doing full blown cloud architecture and development.",
      skills: ["python", "matlab", "AWS"],
    },
    {
      company: "Process Solutions INC",
      dates: "MAY 2016 - NOV 2019",
      position: "Sr Project Engineer/Data Engineer",
      description:
        "Started off as a controls engineer, but soon found my way into the data engineering group of the company.  I developed many data heavy applications, and integrated them directly with control systems.",
      skills: ["python", "javascript", "AWS"],
    },
    {
      company: "Puget Sound Naval Shipyard",
      dates: "NOV 2015 - MAY 2016",
      position: "Electrical Engineer",
      description:
        "Worked as a controls engineer for the shipyard. For my short time there I redesigned a hardware based control system to a software PLC defined one.",
      skills: ["design", "controls", "PLC"],
    },
    {
      company: "US Army",
      dates: "OCT 2004 - SEP 2009",
      position: "Combat Engineer, Sergant",
      description:
        "Served our nation as a combat engineer in wartime and garrison as a leader of soldiers.  Learned many principles of leadership, and lessons about life.",
      skills: ["leadership", "teamwork", "problem solving"],
    },
  ];

  let experienceRowStyle = {
    "border-bottom": "1px solid #e0e0e0",
    "padding-bottom": "15px",
    "padding-top": "15px",
  };

  return (
    <div id="experience">
      <Container className="container-border">
        <h2>Experience</h2>
        {jobHistory.map((job, index) => (
          <div key={index}>
            <Row style={experienceRowStyle}>
              <Col>
                <p>{job.dates}</p>
              </Col>
              <Col>
                <h6 className="font-weight-bold">{job.company}</h6>
                <p className="">{job.position}</p>
                <p>{job.description}</p>
                <div>
                  {job.skills.map((skill, index) => (
                    <Badge
                      bg="warning"
                      className="experience-badge"
                      text="dark"
                    >
                      {skill}
                    </Badge>
                  ))}
                </div>
              </Col>
            </Row>
          </div>
        ))}
<Col className="mt-5 d-flex flex-column justify-content-center align-items-center">
  <p> Download full resume </p>
  {refreshing ? (
    <Button disabled>
      <Spinner animation="border" role="status"></Spinner>
    </Button>
  ) : (
    <Button onClick={resumeClicked}>
      <span className="d-inline-flex justify-content-center align-items-center">
        Download
      </span>
    </Button>
  )}
</Col>

      </Container>
    </div>
  );
};

export default Experience;
