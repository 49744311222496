import React from "react";
import { Container, Row, Col, ProgressBar } from "react-bootstrap";

const Skills = () => {
  const skillsData = [
    {
      Cloud: [
        { name: "AWS", progress: 90, variant: "warning" },
        { name: "Azure", progress: 50, variant: "info" },
        { name: "GCP", progress: 20, variant: "danger" },
        { name: "Alibaba", progress: 1, variant: "success" },
      ],
    },
    {
      Languages: [
        { name: "Golang", progress: 90, variant: "warning" },
        { name: "Python", progress: 85, variant: "info" },
        { name: "Ruby", progress: 80, variant: "danger" },
        { name: "Javascript", progress: 75, variant: "success" },
      ],
    },

    {
      "Tools/Frameworks": [
        { name: "Terraform/Packer", progress: 95, variant: "info" },
        { name: "Jenkins", progress: 95, variant: "success" },
        { name: "Ruby on Rails", progress: 90, variant: "danger" },
        { name: "Docker", progress: 80, variant: "warning" },
      ],
    },

    {
      "AWS Tools": [
        { name: "Lambda", progress: 95, variant: "info" },
        { name: "S3", progress: 95, variant: "danger" },
        { name: "EC2", progress: 90, variant: "success" },
        { name: "VPC", progress: 85, variant: "warning" },
      ],
    },
  ];

  const columnStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    paddingRight: "20px",
    paddingTop: "20px",
  };

  return (
    <div>
      <Container id="skills" className="container-border">
        <h2>Core skills</h2>
        <Row>
          {skillsData.map((data) => {
            return Object.keys(data).map((key) => {
              return (
                <Col xs={0} xl={3} className="mt-xs-6" style={columnStyle}>
                  <h5>{key}</h5>
                  {data[key].map((item) => {
                    return (
                      <div>
                        <label>{item.name}</label>{" "}
                        <ProgressBar
                          variant={item.variant}
                          now={item.progress}
                        />
                      </div>
                    );
                  })}
                </Col>
              );
            });
          })}
        </Row>
      </Container>
    </div>
  );
};

export default Skills;
