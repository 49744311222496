import React, { useState } from "react";
import { Form, Col, Button, Row, Container, Alert, Spinner } from "react-bootstrap";
import { post } from "@aws-amplify/api";

const ContactForm = () => {
  const initialFormData = {
    name: { value: "", error: "" },
    email: { value: "", error: "" },
    message: { value: "", error: "" },
    status: 0
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formSuccess, setFormSuccess] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [formErrors, setFormErrors] = useState(false);

  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      [fieldName]: { value, error: "" },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setRefreshing(true);
      setFormErrors(false);
      setFormSuccess(false);

      const response = await post({
        apiName: "portfolioAPI",
        path: "/contact",
        options: {
          body: formData,
          headers: {
            "Content-Type": "application/json",
          },
        },
      }).response;

 
      const data = await response.body.json();
      setFormData({
        name: { value: data.name.value || "", error: data.name.error || "" },
        email: { value: data.email.value || "", error: data.email.error || "" },
        message: { value: data.message.value || "", error: data.message.error || "" },
        status: data.status,
      });

      switch (data.status) {
        case 200:
          setFormSuccess(true);
          break;
        case 400:
          break;
        default:
          setFormErrors(true);
          break;
      }
      
    } catch (error) {
      setFormErrors(true);
      console.error(error);
    } finally {
      setRefreshing(false);
    }
  };

  return (
    <div id="contact">
      <Container className="container-boarder" container-fluid>
        <Row className="justify-content-left align-items-top h-100">
          <Col  md={6} xs={0} lg={6}>
            <h1>Contact</h1>
            <p>
              Want to know more?
            </p>
            <p>
              <strong>Email: </strong> admin@devin-kim.com 
           </p>
            <p>
              <strong>Phone: </strong> Ask me first 
            </p>
          </Col>
          <Col md={6} xs={12} lg={6}>
            <Form onSubmit={handleSubmit} className="mt-5">
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  name="name"
                  value={formData.name.value}
                  onChange={(e) => handleInputChange(e, "name")}
                />
                {formData.name.error && <div className="text-danger">{formData.name.error}</div>}
              </Form.Group>

              <Form.Group controlId="formEmail">
                <Form.Label className="mt-3">Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={formData.email.value}
                  onChange={(e) => handleInputChange(e, "email")}
                />
                {formData.email.error && <div className="text-danger">{formData.email.error}</div>}
              </Form.Group>

              <Form.Group controlId="formMessage">
                <Form.Label className="mt-3">Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter your message"
                  name="message"
                  value={formData.message.value}
                  onChange={(e) => handleInputChange(e, "message")}
                />
                {formData.message.error && <div className="text-danger">{formData.message.error}</div>}
              </Form.Group>

              {refreshing ? (
                <Button disabled className="mt-3">
                  <Spinner animation="border" role="status" />
                </Button>
              ) : (
                <Button className="mt-3" onClick={handleSubmit}>
                  Submit
                </Button>
              )}

              {formSuccess && (
                <Alert variant="success" className="mt-3">
                  <span>Thanks! You'll be contacted soon</span>
                </Alert>
              )}

              {formErrors && (
                <Alert variant="danger" className="mt-3">
                  <span>Internal server error</span>
                </Alert>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactForm;
