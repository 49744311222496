import React from "react";
import { Navbar, Nav, Col, Row, Spinner, Button } from "react-bootstrap";
import logoImage from "../assets/logo.png";

const Menu = () => {
  const navStyle = {
    backgroundColor: "#002349",
  };

  const logoStyle = {
    marginLeft: "5px",
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      style={navStyle}
      data-bs-theme="dark"
    >
      <Navbar.Brand href="#home" style={logoStyle}>
        <img
          src={logoImage} // Use your logo image as the src
          alt="Logo"
          height="35"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto flex-column">
          <Row>
            <Col>
              <Nav.Link href="#about">About</Nav.Link>
            </Col>
            <Col>
              <Nav.Link href="#skills">Skills</Nav.Link>
            </Col>
            <Col>
              <Nav.Link href="#experience">Experience</Nav.Link>
            </Col>
            <Col>
              <Nav.Link href="#projects">Projects</Nav.Link>
            </Col>
            <Col>
              <Nav.Link href="#contact">Contact</Nav.Link>
            </Col>
          </Row>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Menu;
