import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Project.css";
import "./Container.css";

const Projects = () => {
  let projectData = [
    {
      name: "Cloud Infrastructure Scaling",
      description:
        "Took a large monoltic application and broke it down into cloud based microservices. Consisted of using AWS services such as RDS, S3, Redis.",
    },
    {
      name: "Scan API",
      description:
        "Contributed to a large scale API and infrastructure that was used to scan AWS AMIs for security threats. Used multiple AWS services such as Lambda, Fargate, IAM, API Gateway, piplines, and more.",
    },
    {
      name: "Certificate Provisioning",
      description:
        "Used an ACME API that allowed users to obtain, provision, and renew certificates for a production application. Also developed the UI/backend to allow users to input self signed certificates.",
    },
    {
      name: "DevOps tooling",
      description:
        "Created a Golang based CLI tool that allowed engineers to provision assets via terraform.",
    },
    {
      name: "Analytics Datalake",
      description:
        "Architected, implemented, and led the development of an AWS datalake that was used to store and analyze TBs of data from trucks.",
    },
    {
      name: "Analytics Dashboard",
      description:
        "Developed UI that allowed engineers to visualize data from a datalake. Used services such as s3, Lambda, Athena, and Redshift. Also provided a Tableau dashboard for large scale analytics.",
    },
  ];

  return (
    <div id="projects">
      <Container className="container-border">
        <h2>Major Projects</h2>
        <p>
          Over the course of my career I have contributed to countless projects.
          Here are some projects that I have worked on, these only represent
          some.
        </p>
        <Row className="row-outline">
          {projectData.map((project) => {
            return (
              <Col md={4} className="col-outline">
                <h5> {project.name} </h5>
                <p> {project.description}</p>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default Projects;
