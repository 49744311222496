import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Make sure to import Bootstrap CSS

const Background = () => {
  return (
    <div id="about" className="container border p-3">
      <h2>About me</h2>
      <p>
        I am a software engineer at Microsoft with over 9 years of experience in the tech industry. 
        I have worked for other companies such as Johnson Controls, the Federal Reserve, 
        PACCAR, and had humble beginnings in the US Army. I started my tech career as 
        an Electrical Engineer, but quickly changed gears to software, cloud, and data 
        engineering. I have a passion for learning new technologies and solving complex 
        problems. Also, I consider myself a soccer fan (football for you English folks), 
        having played as a child and now coaching my kid's soccer team. 
      </p>
      <p>
        I also do some freelance web development work for friends. If you're interested, 
        you can check out one of the websites
        <a href="https://angelhavenbythecreek.com" target="_blank" rel="noopener noreferrer" className="ml-2 mr-2 text-decoration-none text-primary"> angelhavenbythecreek.com </a>
        that I currently maintain. For customer inquiries, please email me at 
        <a href="mailto:admin@devin-kim.com" className="ml-2 text-decoration-none text-primary"> admin@devin-kim.com</a>.
      </p>
    </div>
  );
};

export default Background;

