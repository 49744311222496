import React from "react";
import { Container } from "react-bootstrap";

const Footer = () => {
  return (
    <footer className="text-center mt-5">
      <p>&copy; {new Date().getFullYear()} devin-kim.com</p>
      <Container>
        <p className="text-muted">
          Created using React and self hosted in AWS via Amplify. Uses SES,
          Lambda, and S3 for email/resume functionality.{" "}
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
