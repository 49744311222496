import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import Menu from './components/Menu.js';
import Hero from './components/Hero.js';
import Skills from './components/Skills.js';
import ContactForm from './components/Contact.js';
import Footer from './components/Footer.js';
import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';
import Projects from './components/Projects.js';
import Background from './components/Background.js';
import Experience from './components/Experience.js';

Amplify.configure(amplifyconfig);

function App() {
  return (
    <div id="home" className='App'>
      <Menu/>
      <Container xs={3}>
        <Hero/>
        <Skills/>
        <Background/>
        <Experience/>
        <Projects/>
        <ContactForm/>
      </Container>
      <Footer/>
    </div>
  );
}

export default App;
